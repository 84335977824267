<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Novo Usuário" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" />
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <!-- Tabela de usuarios -->
        <DataTable ref="dt" :value="usuarios" v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="50" :filters="filters" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5, 10, 25]" currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)" responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gerenciar Usuários</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="nome" header="Nome" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Nome</span>
              {{ slotProps.data.nome }}
            </template>
          </Column>
          <Column field="email" header="E-mail" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Nome</span>
              {{ slotProps.data.email }}
            </template>
          </Column>
          <Column field="email" header="Empresa" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Empresa</span>
              {{ this.desc_empresas[slotProps.data.id_empresa] }}
            </template>
          </Column>
          <Column field="status" header="Status" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              <span>{{ slotProps.data.status }}</span>
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="apagarUsuarioModal(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <!-- Modal de edicação de usuario -->
        <Dialog v-model:visible="productDialog" :style="{ width: '450px' }" header="Editar Usuário" :modal="true" class="p-fluid">
          <div class="field">
            <label for="Status" class="mb-3">Empresa</label>
            <Dropdown id="empresa" required="true" v-model="usuario.id_empresa" :options="empresas" optionLabel="label" optionValue="value" placeholder="Selecione uma empresa" :class="{ 'p-invalid': submitted && !usuario.id_empresa }"></Dropdown>
            <small class="p-invalid" v-if="submitted && !usuario.id_empresa">Empresa é obrigatório.</small>
          </div>
          <div class="field">
            <label for="name">Nome</label>
            <InputText id="bome" v-model.trim="usuario.nome" required="true" autofocus :class="{ 'p-invalid': submitted && !usuario.nome }" />
            <small class="p-invalid" v-if="submitted && !usuario.nome">Nome é obrigatório.</small>
          </div>
          <div class="field">
            <label for="name">E-mail</label>
            <InputText id="name" v-model.trim="usuario.email" required="true" autofocus :class="{ 'p-invalid': submitted && !usuario.email }" />
            <small class="p-invalid" v-if="submitted && !usuario.email">E-mail é obrigatório.</small>
          </div>
          <div class="field">
            <label for="name">Senha</label>
            <Password id="password" v-model="usuario.password" placeholder="Senha" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
          </div>
          <div class="field">
            <label for="nivel" class="mb-3">Nivel de Acesso</label>
            <Dropdown id="nivel" v-model="usuario.id_tipo" :options="lista_acesso" optionLabel="label" optionValue="value" placeholder="Selecione o tipo de acesso">
            </Dropdown>
          </div>
          <div class="field">
            <label for="Status" class="mb-3">Status</label>
            <Dropdown id="status" v-model="usuario.status" :options="statuses" optionLabel="label" optionValue="value" placeholder="Selecione um status"></Dropdown>
          </div>

          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarUsuario" />
          </template>
        </Dialog>

        <!-- Modal deletar usuario -->
        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="usuario">Certeza que vai apagar: <b>{{ usuario.name }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteProductDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarUsuario" />
          </template>
        </Dialog>

        <!-- Modal deletar varios usuarios -->
        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="product">Apagar usuários selecionados?</span>
          </div>
          <template #footer>
            <Button label="Sim" icon="pi pi-times" class="p-button-text" @click=" deleteProductsDialog = false" />
            <Button label="Não" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>

      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      usuarios: [],
      desc_empresas: [],
      id_empresa: "",
      empresas: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      usuario: {},
      mostrar_usina: false,
      selectedProducts: null,
      filters: {},
      submitted: false,
      status: "",
      nivel_acesso: "",
      usinas: [],
      id_usina: "",
      lista_acesso: [
        { label: "Administrador", value: 1 },
        { label: "Padrão", value: 2 },
        { label: "RPA", value: 3 },
      ],
      statuses: [
        { label: "Inativo", value: 0 },
        { label: "Ativo", value: 1 },
      ],
      limite_usuario: false
    };
  },
  productService: null,
  created() {
    this.initFilters();
  },
  mounted() {
    this.listarUsuario();
    this.listarEmpresa();
    //this.productService.getProducts().then((data) => (this.products = data));
  },
  methods: {

    listarUsuario() {
      this.axios
        .get("/usuarios/listar")
        .then((response) => {

          this.usuarios = response.data.data;
          this.limite_usuario = false
          let user = JSON.parse(localStorage.getItem('user'))

          if (user.id_tipo != "22845207") {
            if (this.usuarios.length < 3) {
              this.limite_usuario = true
            }
          }
          else {
            this.limite_usuario = true
          }
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      return;
    },
    openNew() {
      this.usuario = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },

    // criar/editar usuario
    salvarUsuario() {
      this.submitted = true;

      if (this.usuario.id) {
        // Ediçao do usuario
        this.axios
          .post("usuarios/adicionar", this.usuario)
          .then(() => {

            // console.log(this.usuario)

            this.productDialog = false;
            this.usuario = {};
            this.listarUsuario();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar novo usuario
        this.axios
          .post("/usuarios/adicionar", this.usuario)
          .then(() => {

            // alert(response.data.data)

            this.productDialog = false;
            this.usuario = {};
            this.listarUsuario();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },
    editProduct(usuario) {
      this.productDialog = true;
      this.usuario = { ...usuario };
      // console.log(this.usuario)
    },
    apagarUsuarioModal(usuario) {
      this.usuario = usuario;
      this.deleteProductDialog = true;
    },
    
    listarEmpresa() {
      this.axios
        .post("/empresas/listar")
        .then((response) => {

          let options = [];
          let desc = {};
          response.data.dados.map((el) => {

            let option = { value: el["id"], label: el["razao_social"] };
            desc[el["id"]] = el["razao_social"];
            options.push(option);

          });
          this.empresas = options;
          this.desc_empresas = desc;

          // console.log(this.desc_empresas);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
    
    apagarUsuario() {
      this.axios
      // console.log(this.usuario)
        .post("/usuarios/apagar", this.usuario)
        .then(() => {
          
          this.listarUsuario();
          this.deleteProductDialog = false;
          this.usuario = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Usuário apagado com sucesso!",
            life: 3000,
          });
        })
        .catch(() => {
          
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.usuarios = this.usuarios.filter(
        (val) => !this.selectedProducts.includes(val)
      );

      let obj = this.selectedProducts;
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/usuarios/apagar", obj[key])
          .then(() => {
            this.listarUsuario();
            this.deleteProductDialog = false;
            this.usuario = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usário apagado com sucesso!s",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      });

      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
